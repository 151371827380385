import React from 'react';

class KmField extends React.Component {
    constructor(props) {
        super(props);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(e) {
        // nur lokal validieren und ggf. umformatieren
        this.props.handleInputChange(e);
    }

    render() {
        const km = this.props.km;
        const name = "km";
        const art = this.props.art;
        var disabled = false;
        // var readOnly = false;
        if (art) {
            var auftragFieldList = art.fieldList.split(/\s*,\s*/);
            disabled = auftragFieldList.indexOf(name) < 0;
        }

        return (
            <div className="form-item km-field">
                <label className="field-name">Km<br/>
                    <input value={km}
                           ref={this.props.referrer}
                           name={name}
                           size="3"
                           maxLength="3"
                           onChange={this.handleInputChange}
                           disabled={disabled}
                           onKeyDown={this.props.onKeyDown}
                           placeholder="Km">
                    </input>
                </label>
            </div>
        )

    }

;

}

export default KmField;
