import React, { useState } from 'react';

function LoginForm({Login, app_version, error}) {

    const [details, setDetails] = useState({email:"", password: ""});

    const submitHandler = e => {
        e.preventDefault();

        Login(details);
    }

    return (
        <form id="loginform" onSubmit={submitHandler}>
            <div className="form-inner">
                <h2>LOG IN</h2>
                {(error !== "") ? (<div className="error">{error}</div>) : ""}
                <div className="form-subtitle">
                    {process.env.REACT_APP_NAME} {app_version}
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" name="email" id="email" onChange={e => setDetails({...details, email: e.target.value})} value={details.email}/>
                </div>
                <div className="form-group">
                    <label htmlFor="password">Passwort</label>
                    <input type="password" name="password" id="password" onChange={e => setDetails({...details, password: e.target.value})} value={details.password}/>
                </div>
                <input type="submit" value="Log in"/>

            </div>
        </form>
    );
}

export default LoginForm;