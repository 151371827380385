import React from 'react';
import {Tab, Tabs, TabList, TabPanel } from 'react-tabs';


import Configuration from './Configuration.js';
import Azr from './classes/Azr.js';
import AuftragGrid from './classes/AuftragGrid.js';
import AirlinesGrid from './classes/AirlinesGrid.js';
import GebuehrenGrid from "./classes/GebuehrenGrid";
import InvoiceList from './classes/InvoiceList.js';
import StatisticList from './classes/StatisticList.js';
import FeeList from './classes/FeeList.js';
import UserService from "./shared/UserService";
import LoginForm from "./forms/LoginForm";
import UserControlhandle from "./components/User/controllhandle";

import './App.css';
import 'react-tabs/style/react-tabs.css';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-blue.css';



class App extends React.Component {
    constructor(props) {
        super(props);
        this.config = new Configuration();
        this.onSelectAzr = this.onSelectAzr.bind(this);
        this.onSelectTab = this.onSelectTab.bind(this);
        this.onSelectStammdatenTab = this.onSelectStammdatenTab.bind(this);
        this.onSwitchToAzr = this.onSwitchToAzr.bind(this);
        this.onSwitchToStammdaten = this.onSwitchToStammdaten.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.userService = new UserService();

        this.state = {
            selectedAzr: null,
            selectedTab: 0,
            selectedStammdatenTab: 0,
            mode: "azr",
            user: this.userService.getCurrentUser()
        };
    }

    componentDidMount() {
    }

    onSelectAzr(azr) {
        this.clearState();
        this.setState({
            selectedAzr: azr,
            showDetails: true
        });
    }

    onSelectTab(index) {
        console.log('selected Tab ' + index);
        this.setState({
            selectedTab: index
        });
    }

    onSelectStammdatenTab(index) {
        this.setState({
            selectedStammdatenTab: index
        });
    }


    onSwitchToAzr() {
        this.setState({
            mode: "azr"
        });
    }

    onSwitchToStammdaten() {
        this.setState({
            mode: "stammdaten"
        });
    }

    clearState() {
        this.setState({
            showDetails: false,
            selectedAzr: null,
            editAzr: false,
            newAzr: false
        });
    }

    login(details) {
        console.log('Login as');
        console.log(details.email);
        this.userService.login(details)
            .then(user => {
                    console.log(user)
                    this.setState({'user': user})
                }
            )

    }

    logout() {
        console.log('Logout');
        this.userService.logout();
        this.setState({'user': null});
    }

    render() {
        const selectedAzr = this.state.selectedAzr;
        const showDetails = this.state.showDetails;
        const mode = this.state.mode;
        const user = this.state.user;

        console.log(user);

        // const user = this.userService.getCurrentUser();
        if (!user || user.email === "") {
            return (
                <div className="LoginForm">
                    <LoginForm Login={this.login} app_version={this.config.APP_VERSION}/>
                </div>
            );
        }

        /*
        return (
            <div>
                <h1>You are logged in</h1>
                <button onClick={this.logout}>
                    Logout
                </button>
            </div>
        );
        */
        // eslint-disable-next-lin
        return (
            <div className="fgs-container">
                <header className="fgs-top-panel">
                    <h1>{process.env.REACT_APP_NAME} {this.config.APP_VERSION}</h1>
                    <UserControlhandle Logout={this.logout} User={user}/>
                </header>

                <div className="fgs-content-panel">
                    <div className="fgs-content-innerpanel">

                        <div className="fgs-left-panel">

                            <div className="panel-titlebar">
                                {this.state.mode !== 'azr' &&
                                    <button className="btn btn-mode-switch"
                                            onClick={this.onSwitchToAzr}>
                                        => AZR bearbeiten
                                    </button>
                                }
                                {this.state.mode !== 'stammdaten' &&
                                    <button className="btn btn-mode-switch"
                                            onClick={this.onSwitchToStammdaten}>
                                        => Stammdaten bearbeiten</button>
                                }
                            </div>


                            <div className="inner-panel">
                                {this.state.mode === 'azr' &&
                                    <Azr onSelectAzr={this.onSelectAzr}
                                         selectedAzr={this.state.selectedAzr}
                                    />
                                }

                                {this.state.mode === 'stammdaten' &&
                                    <h2>Stammdaten</h2>
                                }

                            </div>
                        </div>
                        { /* end of fgs-left-panel */}


                        <div className="fgs-main-panel">

                            {mode === 'azr' &&
                                <Tabs className="fgs-tab-control"
                                      onSelect={index => this.onSelectTab(index)}>
                                    <TabList>
                                        <Tab>Aufträge</Tab>
                                        <Tab>Rechnungen</Tab>
                                        <Tab>Statistiken</Tab>
                                        <Tab>Auftragsarten</Tab>
                                    </TabList>

                                    <TabPanel>
                                        {showDetails && selectedAzr &&
                                            <AuftragGrid azr={selectedAzr}/>
                                        }

                                    </TabPanel>

                                    <TabPanel>
                                        {showDetails && selectedAzr &&
                                            <InvoiceList azr={selectedAzr}/>
                                        }
                                    </TabPanel>

                                    <TabPanel>
                                        {showDetails && selectedAzr &&
                                            <StatisticList azr={selectedAzr}/>
                                        }
                                    </TabPanel>

                                    <TabPanel>
                                        {showDetails && selectedAzr &&
                                            <FeeList azr={selectedAzr}/>
                                        }
                                    </TabPanel>

                                </Tabs>
                            }

                            {mode === 'stammdaten' &&
                                <Tabs className="fgs-tab-control"
                                      onSelect={this.onSelectStammdatenTab}>
                                    <TabList>
                                        <Tab>Airlines</Tab>
                                        <Tab>Gebühren</Tab>
                                        <Tab>Auftragsarten</Tab>
                                    </TabList>

                                    <TabPanel>
                                        <AirlinesGrid/>
                                    </TabPanel>

                                    <TabPanel>
                                        <GebuehrenGrid/>
                                    </TabPanel>

                                    <TabPanel>
                                        <h2>Auftragsarten</h2>
                                    </TabPanel>
                                </Tabs>
                            }


                        </div>
                        { /* end of fgs-main-panel */}
                    </div>
                    { /* end of fgs-content-innerpanel */}
                </div>
                { /* end of fgs-content-panel */}

                <footer className="fgs-bottom-panel">
                    <p>FGS Faktura Version {this.config.APP_VERSION}
                        <br/>
                        <small>{process.env.REACT_APP_ENV}, Build {this.config.APP_BUILD}</small>
                    </p>
                </footer>
            </div>
        );
    }
}

export default App;